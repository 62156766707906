import React, { useState } from "react";
import { graphql } from "gatsby";
import Footer from "../components/Footer";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Nav from "../components/nav";
import Feature from "../components/Feature";
import Modal from "react-modal";
import RichTextColumn from "../components/RichTextColumn";
import modalStyle from "./modalStyle.css";
import { Date, Link, RichText } from "prismic-reactjs";
import moment from "moment";
import "moment/locale/fr";

Modal.setAppElement(`#___gatsby`);

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.58)",
  },
  content: {
    position: "relative",
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: "auto",
    maxWidth: "960px",
    margin: "100px auto",
    padding: 0,
    border: 0,
  },
};

const EventsPage = ({ data: { prismicEvents } }) => {
  const { data } = prismicEvents;

  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => {
    setModalOpen(false);
  };

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState(data.events);

  moment.locale("fr");

  const renderModal = () => {
    // Check to see if there's a selected event. If so, render it.
    if (selectedEvent !== null) {
      const event = events[selectedEvent];
      typeof window !== "undefined"
        ? hbspt.forms.create({
            portalId: "8056686",
            formId: `${event.form_id}`,
            target: "#hb-form",
          })
        : "";
      return (
        <Modal
          isOpen={modalOpen}
          onRequestClose={closeModal}
          style={modalStyles}
          className=""
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          contentLabel="Modal">
          <div
            className="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden transform transition-all  sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline">
            <div>
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <svg
                  className="h-6 w-6  text-primary-600"
                  viewBox="0 0 20 20"
                  fill="currentColor">
                  <path
                    fill-rule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-2xl leading-6 text-gray-900 font-bold"
                  id="modal-headline">
                  {event.event_title.text}
                </h3>
                <div className="mt-2 mb-2">
                  <p className="text-sm leading-5 text-gray-500">
                    <RichText render={event.event_description.raw} />
                  </p>
                </div>
                <div
                  id="hb-form"
                  className="bg-gray-100 sm:p-10 p-8 h-full mt-4"></div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <span className="flex rounded-md justify-self-end align-self-end">
                <button
                  type="button"
                  onClick={closeModal}
                  className="inline-flex justify-center  rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                  Retour aux événements
                </button>
              </span>
            </div>
          </div>
        </Modal>
      );
    }
  };

  const onOpenModal = (i) => {
    setModalOpen(true);
    setSelectedEvent(i);
  };

  return (
    <Layout
      title={data.page_title.text}
      description={data.page_description.text}
      thumbnail={data.share_preview.url}>
      <Nav></Nav>
      <Hero
        title={data.events_title.text}
        description={data.events_description.raw}
      />
      {renderModal()}
      <div className="relative bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3"></div>
        </div>
        <div className="relative max-w-screen-xl mx-auto">
      
            <ul>
              {data.events?.map((e, i) => (
                <li
                  className={
                    (i > 0
                      ? "border-t border-gray-200 "
                      : "") + " rounded mt-2 shadow overflow-hidden"
                  }
                  key={i}>
                  <div className="hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out bg-gray-100 flex ">
                    <div
                      className=" p-4 flex items-center justify-center text-sm leading-5 text-white font-bold sm:mt-0 bg-primary-900 flex-col"
                      style={{ width: "20%" }}>
                      <span className="lg:text-3xl text-2xl">
                        {moment(Date(e.event_date)).format("D")}
                      </span>{" "}
                      <span className="my-2 lg:text-lg">
                        {moment(Date(e.event_date)).format("MMM")}{" "}
                      </span>
                      <span className="lg:text-lg">{moment(Date(e.event_date)).format("h:mm")} </span>
                    </div>
                    <div className="px-4 py-4 sm:px-6" style={{ width: "80%" }}>
                      <div className="flex items-center justify-between">
                        <div>
                          <div className="lg:text-xl leading font-bold text-primary-900 flex  ">
                            {e.event_title.text}
                          </div>
                          <div className=" text-sm text-black-600 fade flex overflow-hidden mt-2">
                            {RichText.asText(e.event_description.raw)}
                          </div>
                        </div>
                        <div className="ml-4 flex-shrink-0 flex">
                          <span className="inline-flex ">
                            <a
                              target="_blank"
                              // onClick={() => onOpenModal(i)}
                              href={e.event_url.url}
                              className=" inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-100 hover:bg-gray-50 focus:outline-none focus:ring transition duration-150 ease-in-out mt-4">
                              Détails
                            </a>
                          </span>
                        </div>
                      </div>
                      <div className="mt-4 sm:flex sm:justify-between">
                        <div className="sm:flex">
                          <div className="mr-6 flex items-center text-sm leading-5 text-gray-600 font-bold ">
                            <svg
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-600 mr-2"
                              viewBox="0 0 20 20"
                              fill="currentColor">
                              <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                            </svg>
                            {e.event_label.text}
                          </div>
                          {e.paid_event && (
                            <div className="mt-2 flex items-center text-sm leading-5 text-primary-500 sm:mt-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20.246"
                                height="36"
                                viewBox="0 0 20.246 36"
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-primary-400"
                                fill="currentColor">
                                <path
                                  d="M14.709,16.411,7.116,14.189A2.074,2.074,0,0,1,7.7,10.125h4.662a4.292,4.292,0,0,1,2.4.738,1.1,1.1,0,0,0,1.371-.141l2.447-2.391a1.14,1.14,0,0,0-.127-1.723A9.942,9.942,0,0,0,12.375,4.5V1.125A1.128,1.128,0,0,0,11.25,0H9A1.128,1.128,0,0,0,7.875,1.125V4.5H7.7A7.7,7.7,0,0,0,.035,12.909,8.049,8.049,0,0,0,5.927,19.7l7.207,2.109a2.074,2.074,0,0,1-.584,4.064H7.889a4.292,4.292,0,0,1-2.4-.738,1.1,1.1,0,0,0-1.371.141L1.666,27.668a1.14,1.14,0,0,0,.127,1.723A9.942,9.942,0,0,0,7.875,31.5v3.375A1.128,1.128,0,0,0,9,36h2.25a1.128,1.128,0,0,0,1.125-1.125V31.486a7.942,7.942,0,0,0,7.432-5.112,7.721,7.721,0,0,0-5.1-9.963Z"
                                  transform="translate(-0.002)"
                                />
                              </svg>
                              Payant
                            </div>
                          )}
                          <div className="mt-2 flex items-center font-bold text-sm text-gray-600 leading-5  sm:mt-0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="36"
                              height="36"
                              viewBox="0 0 36 36"
                              className="flex-shrink-0 mr-2 h-5 w-5 text-primary-400"
                              fill="currentColor">
                              <path
                                id="Icon_awesome-medal"
                                data-name="Icon awesome-medal"
                                d="M15.732,9.193l-4.861-8.1A2.25,2.25,0,0,0,8.942,0H1.127A1.125,1.125,0,0,0,.205,1.77L8.029,12.947A14.552,14.552,0,0,1,15.732,9.193ZM34.873,0H27.058a2.25,2.25,0,0,0-1.929,1.093l-4.861,8.1a14.556,14.556,0,0,1,7.7,3.753L35.795,1.77A1.125,1.125,0,0,0,34.873,0ZM18,11.25A12.375,12.375,0,1,0,30.375,23.625,12.375,12.375,0,0,0,18,11.25Zm6.505,11.057-2.667,2.6.631,3.672a.807.807,0,0,1-1.171.85L18,27.695l-3.3,1.733a.807.807,0,0,1-1.171-.85l.631-3.672-2.667-2.6a.808.808,0,0,1,.446-1.377l3.686-.537,1.647-3.341a.809.809,0,0,1,1.449,0l1.647,3.341,3.686.537a.808.808,0,0,1,.446,1.377Z"
                                transform="translate(0)"
                              />
                            </svg>
                            {e.audience}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>


          {!Object.keys(data.events).length && (
            <div className="bg-white shadow overflow-hidden sm:rounded-md p-4 flex flex-col m-16 items-center">
              <p className="text-xl leading text-center text-black-900 flex p-8">
                Nous n'avons pas d'évènement prévu pour l'instant,
                retrouvez-nous sur nos réseaux sociaux pour être tenu au courant
                !
              </p>
              <div className="rounded-md shadow transform hover:-translate-y-1 transition duration-300">
                <a
                  href="/"
                  data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-easing="ease-in-out"
                  data-sal-duration="500"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-primary transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                  Retour à la page d'accueil !
                </a>
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer title={"Les Ops dans une nouvelle dimension"} />
    </Layout>
  );
};

export default EventsPage;

export const pageQuery = graphql`
  query EventsQuery {
    prismicEvents {
      data {
        page_title {
          text
        }
        page_description {
          text
        }
        share_preview {
          url
        }
        events_description {
          raw
        }
        events_title {
          text
        }
        events {
          audience
          event_description {
            raw
          }
          event_label {
            text
          }
          event_title {
            text
          }
          event_url {
            url
          }
          event_date
          paid_event
        }
      }
    }
  }
`;

{
  /* <div
  className="flex flex-col rounded-lg shadow-lg overflow-hidden"
  key={i}
>
  <div className="flex-shrink-0">
    <img
      className="h-48 w-full object-cover"
      src="https://images.prismic.io/demo-bella/a445d9d0-17f2-44d8-9d9d-9a340e790d7c_email_banner_event_suse.png?auto=compress,format"
      alt=""
    />
  </div>

  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
    <div className="flex-1">
      <p className="text-sm leading-5 font-medium text-indigo-600">
        <a href="#" className="hover:underline">
          {e.event_label.text}
        </a>
      </p>
      <a href="#" className="block">
        <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
          SUSE Manager : Salt État de l'art
                      </h3>

        <p className="mt-3 text-base leading-6 text-gray-500">
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Architecto accusantium praesentium eius, ut atque fuga
          culpa, similique sequi cum eos quis dolorum.
                      </p>
      </a>
    </div>
    <div className="mb-2 mt-4 flex items-center">
      <div className="flex-shrink-0">
        <a href="#">
          <img
            className="h-10 w-10 rounded-full"
            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            alt=""
          />
        </a>
      </div>
      <div className="ml-3">
        <p className="text-sm leading-5 font-medium text-gray-900">
          <a href="#" className="hover:underline">
            Arnaud Delin
                        </a>
        </p>
        <div className="flex text-sm leading-5 text-gray-500">
          <time datetime="2020-03-16">Mar 16, 2020</time>
          <span className="mx-1">&middot;</span>
          <span>45 min - Online</span>
        </div>
      </div>
    </div>
    <span className="inline-flex ">
      <a
        href="#"
        target="_blank"
        className=" inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-100 hover:bg-gray-50 focus:outline-none focus:ring transition duration-150 ease-in-out mt-4"
      >
        S'inscrire
                    </a>
    </span>
  </div>
</div> */
}
